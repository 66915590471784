@font-face {
  font-family: 'LibertinusNormal';
  src: url(./styles/fonts/LibertinusSans-Regular.otf);
}

@font-face {
  font-family: 'LibertinusBold';
  src: url(./styles/fonts/LibertinusSans-Regular.otf);
}

@font-face {
  font-family: 'Longhaul';
  src: url(./styles/fonts/Longhaul.ttf);
}

* {
  box-sizing: border-box;
  font-family: Libertinus;
  letter-spacing: 0.04em;
}

.f1 {
  font-size: 48px;
}

.f2 {
  font-size: 38px;
}


.f5 {
  font-size: 16px;
  line-height: 20px;
}

.f6 {
  font-size: 14px;
  line-height: 20px;
}

.f4 {
  font-size: 18px;
}

.dark-blue {
  color: #232B3A;
}

.black {
  color: #293141;
}

.bg-black {
  background-color: #293141;
}

.bg-light-grey {
  background-color: #F4F6F7
}

.grey {
 color: #706C61;
}

.green {
  color: #4bb543;
};

.fadeOut{
     opacity:0;
     transition: opacity 0.5s;
}

.fadeIn {
     opacity:1;
     transition: opacity 0.5s 0.5s;
}

body {
  overflow-x: hidden;
}

.red {
  color:#A21E21;
}

.b--grey{
  border-color: #706C61;
}

.b--red {
  border-color: #A21E21; 
}
